import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "датчики"
    }}>{`Датчики`}</h1>
    <p>{`Система имеет гибкую настройку для подключения разных типов датчиков.`}</p>
    <p>{`На данный момент поддерживаются следующие типы датчиков:`}</p>
    <ul>
      <li parentName="ul">{`Цифровые`}</li>
      <li parentName="ul">{`Аналоговый`}</li>
      <li parentName="ul">{`Частотные`}</li>
    </ul>
    <p>{`Многие системные датчики настраиваются автоматически при поступлении новых данных с трекера. Достаточно изменить протокол в Вашем трекере и если датчик распознается системой, то он отобразится в списке `}<strong parentName="p">{`Системные датчики`}</strong>{` Если датчика там нет, то нужно `}<a parentName="p" {...{
        "href": "#%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B4%D0%B0%D1%82%D1%87%D0%B8%D0%BA%D0%B0"
      }}>{`добавить его`}</a>{`.`}</p>
    <p>{`В TKCRM прописано большое количество датчиков и даны им системные имена. Они одинаковые для всех производителей трекеров. Т.е. если Вы работаете с разными производителями - в большинстве случаев тэги в протоколе будут называться одинаково. Все тэги и их значения, которые отправляет Ваше устроство Вы можете увидеть в конце страницы, как указано на скриншоте. Тут находится самая актуальная информация.`}</p>
    <p><img alt="Last data" src={require("../public/images/last-data-from-device.png")} /></p>
    <p>{`Полный список названий можно посмотреть `}<a parentName="p" {...{
        "href": "/monitoring/sensors/list"
      }}>{`на этой страничке`}</a></p>
    <h2 {...{
      "id": "добавление-датчика"
    }}>{`Добавление датчика`}</h2>
    <p>{`Создадим датчик "состояния открытой двери" для тэга в протоколе `}<strong parentName="p">{`in_d1`}</strong></p>
    <ol>
      <li parentName="ol">{`Нажмите кнопку `}<strong parentName="li">{`Добавить`}</strong></li>
    </ol>
    <p><img alt="Add sensor" src={require("../public/images/sensor-add-1.png")} /></p>
    <p>{`Так как статус имеет всего 2 значения: ноль и единица (в нашем случае дверь открыта и закрыта), то тип датчика будет - цифровой (дискретный).`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Заполняем все поля, выбираем соответствующий тип и тэг.`}</li>
    </ol>
    <p><img alt="Add sensor" src={require("../public/images/sensor-add-2.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Сохраняем`}</li>
    </ol>
    <p>{`Теперь датчик отображается в пользовательском списке:`}</p>
    <p><img alt="Add sensor" src={require("../public/images/sensor-add-3.png")} /></p>
    <p>{`Так же мы можем увидеть текущее состояние в мониторинге`}</p>
    <p><img alt="Add sensor" src={require("../public/images/sensor-add-4.png")} /></p>
    <h2 {...{
      "id": "расчеты-для-датчиков"
    }}>{`Расчеты для датчиков`}</h2>
    <p>{`Для датчиков доступны два вида расчетов:`}</p>
    <ol>
      <li parentName="ol">{`Расчет по формуле`}</li>
      <li parentName="ol">{`Калибровочные таблицы`}</li>
    </ol>
    <h3 {...{
      "id": "расчет-по-формуле"
    }}>{`Расчет по формуле`}</h3>
    <p>{`При создании или редактировании датчика вы можете произвести расчет по любой формуле. Все поступающие данные с трекера будут расчитываться, а затем сохраняться в базу данных для дальнейшего отображения в ПО.`}</p>
    <p>{`На данном примере мы переведем "общий пробег", из метры в километры:`}</p>
    <p><img alt="Sensors formula" src={require("../public/images/sensor-formula.png")} /></p>
    <p>{`Снизу мы можем наблюдать подсказку - как будут выглядеть данные после создания формулы.`}</p>
    <h4 {...{
      "id": "возможные-операторы"
    }}>{`Возможные операторы`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Оператор`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Negation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`+x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unary plus. This converts it's operand to a number, but has no other effect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`x!`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Factorial (x `}<em parentName="td">{` (x-1) `}</em>{` (x-2) `}<em parentName="td">{` … `}</em>{` 2 `}{`*`}{` 1). gamma(x + 1) for non-integers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`abs x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Absolute value (magnitude) of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`acos x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Arc cosine of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`acosh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic arc cosine of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`asin x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Arc sine of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`asinh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic arc sine of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`atan x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Arc tangent of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`atanh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic arc tangent of x (in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`cbrt x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cube root of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ceil x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ceiling of x — the smallest integer that’s >= x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`cos x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cosine of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`cosh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic cosine of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`exp x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`e^x (exponential/antilogarithm function with base e)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`expm1 x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`e^x - 1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`floor x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Floor of x — the largest integer that’s <= x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`length x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String length of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ln x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Natural logarithm of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`log x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Natural logarithm of x (synonym for ln, not base-10)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`log10 x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Base-10 logarithm of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`log2 x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Base-2 logarithm of x`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`log1p x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Natural logarithm of (1 + x)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`not x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Logical NOT operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`round x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`X, rounded to the nearest integer, using "grade-school rounding"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sign x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sign of x (-1, 0, or 1 for negative, zero, or positive respectively)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sin x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sine of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sinh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic sine of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sqrt x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Square root of x. Result is NaN (Not a Number) if x is negative.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tan x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tangent of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tanh x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hyperbolic tangent of x (x is in radians)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`trunc x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Integral part of a X, looks like floor(x) unless for negative number`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "встроенные-функции-для-формулы"
    }}>{`Встроенные функции для формулы`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Функция`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`random(n)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Get a random number in the range [0, n). If n is zero, or not provided, it defaults to 1.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fac(n)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`n! (factorial of n: "n `}<em parentName="td">{` (n-1) `}</em>{` (n-2) `}<em parentName="td">{` … `}</em>{` 2 `}{`*`}{` 1") Deprecated. Use the ! operator instead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`min(a,b,…)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Get the smallest (minimum) number in the list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`max(a,b,…)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Get the largest (maximum) number in the list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`hypot(a,b)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hypotenuse, i.e. the square root of the sum of squares of its arguments.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`pyt(a, b)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alias for hypot.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`pow(x, y)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Equivalent to x^y. For consistency with JavaScript's Math object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`atan2(y, x)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Arc tangent of x/y. i.e. the angle between (0, 0) and (x, y) in radians.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`roundTo(x, n)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rounds x to n places after the decimal point.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`map(f, a)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array map: Pass each element of `}<inlineCode parentName="td">{`a`}</inlineCode>{` the function `}<inlineCode parentName="td">{`f`}</inlineCode>{`, and return an array of the results.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fold(f, y, a)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array fold: Fold/reduce array `}<inlineCode parentName="td">{`a`}</inlineCode>{` into a single value, `}<inlineCode parentName="td">{`y`}</inlineCode>{` by setting `}<inlineCode parentName="td">{`y = f(y, x, index)`}</inlineCode>{` for each element `}<inlineCode parentName="td">{`x`}</inlineCode>{` of the array.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`filter(f, a)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array filter: Return an array containing only the values from `}<inlineCode parentName="td">{`a`}</inlineCode>{` where `}<inlineCode parentName="td">{`f(x, index)`}</inlineCode>{` is `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`indexOf(x, a)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Return the first index of string or array `}<inlineCode parentName="td">{`a`}</inlineCode>{` matching the value `}<inlineCode parentName="td">{`x`}</inlineCode>{`, or `}<inlineCode parentName="td">{`-1`}</inlineCode>{` if not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`join(sep, a)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Concatenate the elements of `}<inlineCode parentName="td">{`a`}</inlineCode>{`, separated by `}<inlineCode parentName="td">{`sep`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`if(c, a, b)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function form of c ? a : b. Note: This always evaluates both `}<inlineCode parentName="td">{`a`}</inlineCode>{` and `}<inlineCode parentName="td">{`b`}</inlineCode>{`, regardless of whether `}<inlineCode parentName="td">{`c`}</inlineCode>{` is `}<inlineCode parentName="td">{`true`}</inlineCode>{` or not. Use `}<inlineCode parentName="td">{`c ? a : b`}</inlineCode>{` instead if there are side effects, or if evaluating the branches could be expensive.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "тарировочная-таблица"
    }}>{`Тарировочная таблица`}</h3>
    <p>{`Для того, чтобы расчитать датчики по тарировочной таблице - заполните X и Y в соответствии с полученными данными`}</p>
    <p><strong parentName="p">{`X`}</strong>{` - входящее значение`}</p>
    <p><strong parentName="p">{`Y`}</strong>{` - результат`}</p>
    <p><img alt="Sensor calibration table" src={require("../public/images/sensor-calibration-table.png")} /></p>
    <p>{`После Вам будет доступен график с расчетами`}</p>
    <p><img alt="Sensor calibration table" src={require("../public/images/sensor-calibration-table2.png")} /></p>
    <p>{`Данные можно вводить группой (например копировать из Excel). Для этого нажмите на `}<strong parentName="p">{`сменить поле ввода`}</strong>{` и скопируйте Ваши расчеты`}</p>
    <p><img alt="Sensor calibration table" src={require("../public/images/sensor-calibration-table3.png")} /></p>
    <p>{`По желанию можно указывать `}<strong parentName="p">{`Верхнюю и нижнюю`}</strong>{` границу. В таком случае данные будут фильтроваться при добавлении в базу данных`}</p>
    <p><img alt="Sensor calibration table" src={require("../public/images/sensor-calibration-table4.png")} /></p>
    <h2 {...{
      "id": "датчик-зажигания"
    }}>{`Датчик зажигания`}</h2>
    <p>{`Если ваш трекер отправляет напряжение сети, к которой он подключен, то датчик зажигания настроится и откалибруется автоматически. Для этого нужно будет завести и заглушить транспортное средство.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Датчик зажигания рекомендуется устанавливать во всех случаях, если это возможно. Так как на его основе формируется подробная статистика по рейсам, стоянкам, сливам топлива и прочее...`}</p>
    </blockquote>
    <p>{`Если Вы хотите настроить датчик на дискретный вход, то укажите тэг протокола в этом поле (в нашем случае это `}<strong parentName="p">{`in_d0`}</strong>{`):`}</p>
    <p><img alt="Set ignition" src={require("../public/images/set-ignition.png")} /></p>
    <h2 {...{
      "id": "топливные-датчики"
    }}>{`Топливные датчики`}</h2>
    <p>{`Топливные датчики настраиваются как и любые другие. Данные можно принимать как с `}<strong parentName="p">{`ДУТ`}</strong>{`, так и с `}<strong parentName="p">{`CAN шины`}</strong>{`. За одним исключением - топливные датчики отображаются в отдельном списке и могут группироваться по бакам.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Если топливные датчики добавить в пользовательские, то по ним `}<strong parentName="p">{`не будет`}</strong>{` вестись статистика по топливу и создаваться отчеты`}</p>
    </blockquote>
    <p><img alt="Fuel sensor total" src={require("../public/images/sensor-fuel-1.png")} /></p>
    <ol>
      <li parentName="ol">{`Добавить бак (Если его нет)`}</li>
      <li parentName="ol">{`Добавить датчик в текущий бак`}</li>
      <li parentName="ol">{`Указываем Ваш тэг в протоколе`}</li>
      <li parentName="ol">{`Тип топливного датчика: `}<strong parentName="li">{`Уровень топлива`}</strong></li>
      <li parentName="ol">{`Заполняем калибровучную таблица, если это нужно`}</li>
    </ol>
    <h3 {...{
      "id": "пример-для-дут"
    }}>{`Пример для ДУТ`}</h3>
    <p><img alt="Fuel sensor total" src={require("../public/images/sensor-fuel-2.png")} /></p>
    <h3 {...{
      "id": "пример-для-can-шины"
    }}>{`Пример для CAN шины`}</h3>
    <p>{`В нашем случае данные приходят в миллилитрах. Для того, чтобы получить литры соответственно нужно разделить на 10:`}</p>
    <p><img alt="Fuel sensor total" src={require("../public/images/sensor-fuel-3.png")} /></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Нажимаем `}<strong parentName="li">{`Добавить датчик`}</strong>{` и всё`}</li>
    </ol>
    <p>{`На страничке мониторинга доступен результат`}</p>
    <p><img alt="Fuel sensor total" src={require("../public/images/sensor-fuel-4.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`Раздел дополняется`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      